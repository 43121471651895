import styled from "@emotion/styled";
import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        fontFamily: "HillRegular",
        fontSize: 16
    },
    "& .MuiInputLabel-formControl": {
        fontFamily: "HillRegular",
        fontSize: 16
    }
});


export default function AutoSuggestChips({ autoSuggestData, state, setState }) {
    const deleteTagChip = (tag) => {
        let selectedTags = [...state.tags];
        const newTags = selectedTags.filter(x => x !== tag);
        setState({ ...state, tags: newTags });
    }

    const addTagChip = (tag) => {
        const newTag = tag.find(x => !state.tags.includes(x));

        if (!newTag) {
            return
        }
        let selectedTags = [...state.tags];
        selectedTags = [...selectedTags, newTag];
        setState({ ...state, tags: selectedTags });
    }

    return (
        <StyledAutocomplete
            closeIcon={false}
            freeSolo={true}
            multiple
            id="fixed-tags-demo"
            value={state.tags}
            onChange={(event, newTag) => addTagChip(newTag)}
            options={autoSuggestData.map(x => x.name)}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => deleteTagChip(option)}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Tags"
                    variant="outlined"
                    placeholder="Tilføj tag"
                />
            )}
        />
    )
}
