import { Autocomplete, TextField } from "@mui/material";
import styled from "@emotion/styled";

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        fontFamily: "HillRegular",
        fontSize: 16
    },
    "& .MuiInputLabel-formControl": {
        fontFamily: "HillRegular",
        fontSize: 16
    }
});

export default function InputWithAutosuggest({
    suggestedOptions,
    label,
    elementTypeError,
    handleSelect,
    value,
    isEdit
}) {
    const instruction = "Tryk enter efter indtastning";
    return (
        <div>
            <StyledAutocomplete
                value={value}
                disabled={isEdit}
                freeSolo={true}
                disablePortal
                onChange={(e, value) => handleSelect(value)}
                id="select-easy"
                options={suggestedOptions}
                renderInput={(params) => <TextField {...params} label={label} />}
            />
            <div style={{
                color: elementTypeError ? "#FF3333" : "#B0B0B0"
            }}>
                {elementTypeError ? elementTypeError : instruction}
            </div>
        </div>
    );
}