import React, { useContext, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import {
    Checkbox, Dialog, DialogActions,
    DialogContent, DialogTitle, FormControl,
    FormControlLabel,
    IconButton,
    InputBase,
    Paper,
    Tooltip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CampaignContext } from '../../../context/CampaignContext';
import { getCampaignElementTypes, shareCampaignLinkViaEmail } from '../../requests/Requests';
import { generateUrlWithParameters } from '../../requests/Helpers';
import ButtonCustomStyled from '../DialogComponents/DialogActionButton';
import styles from '../../../assets/css/dialogContent.module.css'


export default function ShareCampaignDialog(props) {
    const {
        openDialogAddNewCampaign: openDialogAddNewCampaignBase,
        selectedCampaign: selectedCampaignBase,
        openDialogEditCampaign: openDialogEditCampaignBase,
        dialogShareCampaign: dialogShareCampaignBase,
        campaignAssetSections: campaignAssetSectionsBase,
    } = useContext(CampaignContext);

    const [dialogShareCampaign, setDialogShareCampaign] = dialogShareCampaignBase;
    const [campaignAssetSections, setCampaignAssetSections] = campaignAssetSectionsBase;
    const [selectedCampaign, setSelectedCampaign] = selectedCampaignBase;

    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [sectionTypesUsed, setSectionTypesUsed] = useState([]);

    const [shareUrl, setShareUrl] = useState(false);
    const [state, setState] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [email, setEmail] = useState({
        emailInput: "",
        isValid: ""
    });
    const [internalLink, setInternalLink] = useState(false);
    const [sectionSelectionIsValid, setSectionSelectionIsValid] = useState(true);


    useEffect(() => {
        const runEffect = async () => {
            await getSectionTypeNames();
        };
        runEffect();
    }, []);

    useEffect(() => {
        generateShareUrl();
    })

    const populateState = (types) => {
        const stateTemp = {};
        const _sectionTypes = [];
        campaignAssetSections.forEach(x => {
            // find type correcponding to section type
            const typesUsed = types.find(y => y.id === x.sectionType);
            if (typesUsed) {
                stateTemp[typesUsed.name] = false;
                _sectionTypes.push(typesUsed);
            }
        });
        setState(stateTemp);
        setSectionTypesUsed(_sectionTypes);
    }

    const getSectionTypeNames = async () => {
        const types = await getCampaignElementTypes();

        populateState(types);
    }

    const handleCheckboxClicked = async (event) => {
        setState({ ...state, [event.target.name]: event.target.checked, });
    }

    const handleSelectAllSections = (e) => {
        let _stateCopy = { ...state };
        Object.keys(_stateCopy).forEach(key => {
            _stateCopy[key] = e.target.checked;
        });

        setState(_stateCopy);
    }

    const generateShareUrl = async () => {
        const selectedSection = Object.values(state).find(value => value === true);
        const isValid = selectedSection ? true : false;
        if (!isValid) {
            setShareUrl("");
            return
        }

        const selectedSectionTypeIds = getSelectedSectionIds();
        const _url = await composeShareUrl(selectedSectionTypeIds);

        setShareUrl(_url);

    }
    

    const getSelectedSectionIds = () => {
        let sectionTypeIds = [];
        Object.keys(state).forEach(key => {
            if (state[key] === true) {
                const sectionType = sectionTypesUsed.find(x => x.name === key);
                if (sectionType) {
                    // const sectionDbRecord = campaignAssetSections.find(y => y.sectionType === sectionType.id && y.campaignId === selectedCampaign.id);
                    // if (sectionDbRecord) { sectionTypeIds.push(sectionDbRecord.id) }
                    sectionTypeIds = [...sectionTypeIds, sectionType.id];
                }
            }
        })
        return sectionTypeIds;
    }

    const composeShareUrl = async (selectedSectionTypeIds) => {
        let sharedLink = "";

        // get absolute path
        if (internalLink) {
            sharedLink = window.location.href;
        } else {
            const hostUrl = window.location.href.split("/");
            const host = hostUrl[0] + "//" + hostUrl[2];
            sharedLink = await generateUrlWithParameters(host, selectedCampaign.id, selectedSectionTypeIds);
        }
        return sharedLink;
    }
    const copyToClipBoard = () =>{
        navigator.clipboard.writeText(shareUrl);
    }

    
    return (
        <Dialog
            open={true}
            onClose={() => setDialogShareCampaign(false)}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle className={styles.title}>
                Del kampagneelementer
            </DialogTitle>
            <DialogContent style={{ padding: "0px 40px 40px 40px" }}>
                <div className={styles.dialogContent}>
                    <Box sx={{ display: 'flex' }}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        labelStyle={{
                                            fontSize: 16,
                                            fontFamily: "HillRegular"
                                        }}
                                        checked={selectAll}
                                        onChange={(e) => { handleSelectAllSections(e); setSelectAll(e.target.checked) }}
                                        name="Alle" />
                                }
                                label="Alle"
                            />
                            {sectionTypesUsed.map((x, index) => <FormControlLabel
                                control={
                                    <Checkbox
                                        key={index}
                                        checked={x && state[x.name]}
                                        onChange={(e) => handleCheckboxClicked(e)}
                                        name={x.name} />
                                }
                                label={x.name}
                            />)}
                        </FormControl>
                    </Box>
                    <Box sx={{ m: 3 }}>
                        <Paper elevation={1} style={{marginTop:"15px"}}>
                            <InputBase placeholder="Vælg et afsnit for at generere en url" style={{padding:"10px", width:"90%"}}
                                disabled
                                value={shareUrl}
                            />
                            <Tooltip title="Kopier til clipboard">
                                <IconButton>
                                    <ContentCopyIcon onClick={() => copyToClipBoard()} />
                                </IconButton>
                            </Tooltip>
                        </Paper>
                    </Box>
                </div>
                <div
                    className={styles.dialogButtons}>{sectionSelectionIsValid ? "" : "Vælg mindst et afsnit"}</div>
            </DialogContent>
            <DialogActions style={{
                padding: "0px 40px 40px 40px"
            }}>
                <ButtonCustomStyled
                    text="Annuller"
                    onClick={() => setDialogShareCampaign(false)}
                />
               
            </DialogActions>
        </Dialog>
    )
}
