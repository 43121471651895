import { Grid } from "@mui/material";
import CampaignItem from "../../campaignAssets/campaignItem/CampaignItem";
import styles from '../../../assets/css/assetsLayout.module.css'

export default function CampaignSectionPublic({ banner, types, assets, data, sectionName
}) {
    return (
        <div className="page-break"
            style={{
                borderBottom: "1px solid #e9e9e9",
                padding: "0px 20px 10px 20px"
            }}>
            <div className={styles.campaignBody}>
                <div
                    id={sectionName}
                    className={styles.campaignSection}>
                    <span>
                        {sectionName}
                    </span>
                </div>
            </div>
            <Grid container spacing={0.5}>
                {
                    data.map((x, index) =>
                        // <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                        <span style={{
                            marginRight: 30
                        }}>
                            <CampaignItem
                                item={x}
                                sectionName={sectionName}
                                isPublic={true}
                            />
                        </span>

                    )
                }
            </Grid>
        </div>
    )
}