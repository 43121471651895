import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FilePond, registerPlugin } from "react-filepond";
import { CampaignContext } from '../../context/CampaignContext';
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
    createCampaignElement, fetchCampaignAssets,
    fetchCampaignElementSections, getCampaignElementTypes,
    updateElementSection
} from '../requests/Requests';
import InputWithAutosuggest from './DialogComponents/InputWithAutosuggest';
import { isZip } from '../requests/Helpers';
import { useTheme } from '@mui/system';
import StyledTextFiled from './DialogComponents/StyledTextFiled';
import ButtonCustomStyled from './DialogComponents/DialogActionButton';
import styles from '../../assets/css/dialogContent.module.css'

registerPlugin(FilePondPluginImagePreview);


export default function DialogUploadFile(props) {
    const theme = useTheme();
    const [files, setFiles] = useState([]);
    const [elementType, setElementType] = useState(undefined);
    const [elementTypeError, setElementTypeError] = useState(undefined);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        openDialogUploadFile: openDialogUploadFileBase,
        selectedCampaign: selectedCampaignBase,
        campaignAssets: campaignAssetsBase,
        campaignElementTypes: campaignElementTypesBase,
        campaignAssetSections: campaignAssetSectionsBase,
        dialogEditAssetSections: dialogEditAssetSectionsBase
    } = useContext(CampaignContext);

    const [, setOpenDialogUploadFile] = openDialogUploadFileBase;
    const [selectedCampaign,] = selectedCampaignBase;
    const [, setCampaignAssets] = campaignAssetsBase;
    const [campaignElementTypes, setCampaignElementTypes] = campaignElementTypesBase;
    const [campaignAssetSections, setCampaignAssetSections] = campaignAssetSectionsBase;
    const [dialogEditAssetSections, setDialogEditAssetSections] = dialogEditAssetSectionsBase;

    const { isEdit, sectionData } = props;

    useEffect(() => {
        if (isEdit && sectionData) {
            const type = campaignElementTypes.find(x => x.id === sectionData.sectionType);
            setComment(sectionData.comment);
            setElementType(type.name);
        } else {
            setComment("");
            setElementType(undefined);
        }
    }, []);

    const handleSetFile = async (fileItems) => {
        setFiles(fileItems.map(fileItem => fileItem.file));
    }

    const handleSelectElementType = (value) => {
        setElementType(value);
        // find id of the selected type to find a comment that belongs to the section, not element. Jakob's wish
        const type = campaignElementTypes.find(x => x.name === value);
        if (type) {
            const assetSection = campaignAssetSections.find(x => x.sectionType === type.id);
            if (assetSection) {
                setComment(assetSection.comment);
            } else {
                setComment("");
            }
        }
        document.getElementById("comment").focus();
    }

    const handleSave = () => {
        isEdit ? editSectionElements() : uploadManyFiles();
    };

    const editSectionElements = async () => {
        setLoading(true);
        const formData = composeRequestBody();

        await updateElementSection(formData);

        await updateState();
        setLoading(false);
    }

    const uploadManyFiles = async () => {
        setLoading(true);
        if (!validation()) {
            setLoading(false);
            return;
        }

        await handleSaveElement();

        await updateState();

    }

    const updateState = async () => {
        const _campaignElementTypes = await getCampaignElementTypes();
        setCampaignElementTypes(_campaignElementTypes);

        const campaignAssets = await fetchCampaignAssets(selectedCampaign.id);
        setCampaignAssets(campaignAssets);

        const _sections = await fetchCampaignElementSections(selectedCampaign.id);
        setLoading(false);
        setCampaignAssetSections(_sections);
        handleClose();
    }

    const composeRequestBody = () => {
        const formData = new FormData();
        formData.append("campaignId", selectedCampaign.id);
        formData.append("elementType", elementType);
        formData.append("comment", comment);

        files.forEach(file => formData.append("files", file));

        if (files.length > 0 && isZip(files[0])) {
            formData.append("isCompressed", true);
        };

        return formData;
    }

    // upload a single file, currently not used but a func for multiple is not tested properly
    const handleSaveElement = async () => {
        const formData = composeRequestBody();

        await createCampaignElement(formData);
    }

    const validation = () => {
        if (!elementType) {
            setElementTypeError("Vælg venlist et element type");
        } else {
            setElementTypeError(undefined);
        }
        return files.length > 0 && elementType;
    }

    const handleClose = () => {
        !isEdit ? setOpenDialogUploadFile(false) :
            setDialogEditAssetSections(false)
    }

    return (
        <Dialog
            open={true}
            onClose={() => handleClose()}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle className={styles.uploadFileBody}>
                {!isEdit ? "Opret nyt element" : "Rediger element"}
            </DialogTitle>
            <DialogContent style={{ padding: "0px 40px 40px 40px" }}>
                <div className={styles.uploadInputFields}>
                    <div>
                        <InputWithAutosuggest
                            suggestedOptions={campaignElementTypes.map(x => x.name)}
                            label="Elementtype"
                            handleSelect={(value) => handleSelectElementType(value)}
                            elementTypeError={elementTypeError}
                            value={elementType}
                            isEdit={isEdit}
                        />
                        <StyledTextFiled
                            value={comment}
                            disabled={false}
                            label="Kommentar"
                            onChange={(value) => setComment(value)}
                            text="comment"
                            id="comment"
                        />
                    </div>
                    <div>
                            <small>Godkendte elementer til upload: PNG, GIF, JPG, JPEG, MP4, PDF og Zip fil(indeholdende flere banner). <br/>En Zip fil skal indeholde
                            en index.html file, eksempel på en Zip-fil: Zip-filen > banner navn mappe > index.html. 
                            </small>
                        <FilePond
                            allowImagePreview={true}
                            files={files}
                            onupdatefiles={(fileItems) => handleSetFile(fileItems)}
                            allowMultiple={true}
                            maxFiles={10}
                            imagePreviewHeight={400}
                            // acceptedFileTypes={["image/*"]}
                            labelIdle={
                                'Træk og slip dit element eller <span class="filepond--label-action">Gennemse</span> (JPG, GIF, MP4, HTML)'
                            }
                            style={{
                                backgroundColor: "#000000"
                            }}
                            />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{
                padding: "0px 40px 40px 40px"
            }}>
                <ButtonCustomStyled
                    text="Annuller"
                    onClick={() => handleClose()}
                    disabled={loading}
                />
                <ButtonCustomStyled
                    text={loading ? "Loading..." : "Gem kampagne"}
                    onClick={() => handleSave()}
                    primary={true}
                    disabled={loading}
                />
            </DialogActions>
        </Dialog>
    )
}
