import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";
import { theme } from "../../ShowCaseTheme";
import TopBar from "../layout/TopBar";
import AssetsLayoutPublic from "./publicLayout/AssetsLayoutPublic";

export default function PublicViewBase() {
    return (
        <ThemeProvider theme={theme}>
        <div>
            <TopBar
                logUserOut={() => console.log("")}
                isPublic={true}
            />

            <AssetsLayoutPublic />
        </div>
        </ThemeProvider>
    )
}