import { ThemeProvider } from '@mui/material';
import { theme } from './ShowCaseTheme';
import './App.css';
import StartPage from './components/layout/StartPage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <StartPage />
      </div>
    </ThemeProvider>
  );
}

export default App;
