import * as React from 'react';

export default function MenuButton({ text, func, isActive }) {
    return (
        <div
            onClick={() => func ? func() : undefined}
            className={isActive ? "action-menu-button-active" : "action-menu-button"}
        >
            {text}
        </div>

    );
}