import { createParameterReplacer, getFileEndpoint, getPdfFileBase64, previewFileEndpoint } from "./Requests";

export function apiGetParamsReplace(endpoint, ...keys) {
    var result = endpoint;
    keys.forEach(function (key, i) {
        result = result.replace("{" + i + "}", key);
    });
    return result;
}

export async function getFileBase(files) {
    return await Promise.all(
        files.map(async item => {
            return await getBase64(item);
        })
    )
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        if (file == null) {
            resolve(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        }
    });
}

export function getBlobFromBase64(data) {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
}

export function groupByProperty(array,) {
    return array.reduce((group, product) => {
        const { category } = product;
        group[category] = group[category] ?? [];
        group[category].push(product);
        return group;
    }, {});

} 

export function isPdf(item) {
    const extension = item.name.split(".")[1];
    return extension === "pdf";
}

export function isZip(item) {
    const extension = item.name.split(".")[1];
    return extension === "zip";
}

export function formatBodyParamsPOST(params) {
    var formBody = [];
    for (var property in params) {
        var encodedKey = encodeURIComponent(property);

        var encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody;
}

export function stringIsEmptyOrWhiteSpace(string) {
    return (!(/\S/.test(string)));
}

export function getBannerSize(bannerName) {
    const initialIndex = bannerName.lastIndexOf("x");
    let width = "";
    let heigth = "";

    for (let i = initialIndex - 1; i < initialIndex; i--) {
        const prevCharacter = bannerName[i];

        if (!isNaN(prevCharacter)) {
            width = width.slice(0, 0) + prevCharacter + width.slice(0);
        } else {
            break;
        }
    };

    for (let i = initialIndex + 1; i < bannerName.length; i++) {
        const nextCharacter = bannerName[i];

        if (!isNaN(nextCharacter)) {
            heigth = heigth.slice(0, heigth.length) + nextCharacter + heigth.slice(heigth.length);
        }
        else { break; }
    };

    return [width, heigth];
}

export function getToken() {
    return localStorage.getItem("access_token");
}

export async function generateUrlWithParameters(url, campaignId, selectedSectionTypes) {
    let urlBase = url;
    urlBase = `${urlBase}/public`;

    let sectionParameters = selectedSectionTypes.map((id, index) => {
        let param = ``;
        if (index === 0) {
            param = `${id}`
        };
        param = index === 0 ? `${id}` : `&${id}`;
        return param;
    });
    sectionParameters = sectionParameters.toString().replace(/,/g, "");

    // save params in DB and get GUID in return
    const requestBody = {
        campaignId: campaignId,
        sectionGrouping: sectionParameters
    };

    const recordDb = await createParameterReplacer(requestBody);
    return `${urlBase}/${recordDb.replacer}`; // it is a guid

}

export function generateUrlForInternalUse(url, campaignId) {
    let urlBase = url;

    return urlBase = `${urlBase}/campaign/${campaignId}/`;
}

export async function getFileBaseToUploadMultiple(files) {
    return await Promise.all(
        files.map(async item => {
            const data = await getBase64(item);
            return receiveFile(data, item);
        })
    )
}

export function receiveFile(data, item) {
    return {
        FileName: item.name,
        File: removeInfoFromBase64(data)
    };
}

function removeInfoFromBase64(str) {
    return str.split(",")[1];
}

