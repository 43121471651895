import { useTheme } from "@emotion/react";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { CampaignContext } from "../../context/CampaignContext";
import { customersEnum } from "../constants/Enums";
import { getCampaignsOfTheYear } from "../requests/Requests";
import styles from '../../assets/css/sideMenu.module.css'

export default function AccordionItem({ title, content }) {

    const {
        selectedCampaign: selectedCampaignBase,
        campaignsToYearMap: campaignsToYearMapBase,
    } = useContext(CampaignContext);

    const [selectedCampaign, setSelectedCampaign] = selectedCampaignBase;
    const [campaignsToYearMap, setCampaignsToYearMap] = campaignsToYearMapBase;

    const [isActive, setIsActive] = useState(false);

    const theme = useTheme();


    React.useEffect(() => {
        const runEffect = async () => {
            if (selectedCampaign && selectedCampaign.year === title) {
                await loadData();
            }
        };
        runEffect();
    }, [selectedCampaign]);

    const loadData = async () => {
        setIsActive(true);
        await fetchCampaigns(selectedCampaign.year);
    }

    const fetchCampaigns = async (year) => {
        // check if it was fetched before
        if (campaignsToYearMap.has(year) && campaignsToYearMap.get(year).length > 0) {
            return;
        }
        const campaigns = await getCampaignsOfTheYear(year);

        const _stateClone = new Map(campaignsToYearMap);

        _stateClone.set(year, campaigns);
        setCampaignsToYearMap(_stateClone);
    };

    const renderCampaignDisplayName = (campaign) => {
        const customers = customersEnum.filter(x => campaign.customerIds.includes(x.id)).map(y => y.displayName);

        const customersStr = customers.join("/");
        return `${campaign.jobNumber} ${customersStr} ${campaign.jobTitle} uge ${campaign.week} ${campaign.year}`;
    }

    let navigate = useNavigate();

    const handleView = (id) => {
        navigate("/campaign/" + id);
    }

    return (
        <div className={styles.body}>
            <div
                style={{
                    color: theme.palette.text.primary,
                    cursor: "pointer"
                }}
                onClick={() => setIsActive(!isActive)}>
                <div
                    style={{
                        fontFamily: "HillBold",
                    }}
                    onClick={() => fetchCampaigns(title)}>
                    {title}
                </div>
            </div>
            {isActive && <div className={styles.bodyItem}>
                {campaignsToYearMap.get(title).map(campaign => (
                    <div
                        key={campaign.id}
                        style={{ 
                            padding: "5px 0px 5px 0px",
                            cursor: "pointer"
                        }}>
                        <div
                            onClick={() => { setSelectedCampaign(campaign); handleView(campaign.id) }}
                            style={{ color: selectedCampaign && selectedCampaign.id === campaign.id ? "#ff8862" : "#133f26" }}
                        >
                            {renderCampaignDisplayName(campaign)}
                        </div>
                    </div>
                ))}
            </div>}
        </div>
    );
};
