import React from 'react';
import CampaignHeader from './campaignHeader/CampaignHeader';
import CampaignTags from './tags/CampaignTags';


export default function HeaderSection({ selectedCampaign }) {
    return (
        <div>
            <CampaignHeader
                selectedCampaign={selectedCampaign}
            />
            <CampaignTags
                selectedCampaign={selectedCampaign} />
        </div>
    )
}