import { createCampaign } from "../requests/Requests";

export async function addNewCampaign(dto) {

    const newCampaign = {
        jobNumber: dto.jobNumber,
        jobTitle: dto.jobName,
        comment: dto.comment,
        week: dto.week,
        year: parseInt(dto.year),
        folderPath: `\\${dto.jobNumber}`,
        customerIds: [parseInt(dto.customer)],
        tags: dto.tags
    };
    const campaign = await createCampaign(newCampaign);
    return campaign;
}