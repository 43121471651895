import { TextField } from "@mui/material";
export default function StyledTextFiled({
    value, disabled, label, onChange, validation, text, id
}) {
    return (
        <TextField
            autoFocus
            value={value}
            disabled={disabled}
            margin="dense"
            id={id ? id : text}
            label={label}
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => onChange(e.target.value, text)}
            helperText={validation && !!validation ?
                <span style={{ color: "#ff0000" }}>{validation}</span> : ' '}
            inputProps={{
                style: {
                    fontSize: 16,
                    fontFamily: "HillRegular"
                },
            }}
            InputLabelProps={{
                style:
                {
                    fontSize: 16,
                    fontFamily: "HillRegular"
                },
            }}
        />
    )
}