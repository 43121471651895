import * as React from 'react';
import { CampaignContext } from '../../../context/CampaignContext';
import HeaderSection from '../header/HeaderSection';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { fetchBanner } from '../../requests/Requests';
import DialogUploadFile from '../../dialogs/DialogUploadFile';
import MenuButton from '../actionButtons/menuButton/MenuButton';
import ReactToPrint from 'react-to-print';
import PrintableComponent from "./PrintableComponent";
import { isValidDateValue } from '@testing-library/user-event/dist/utils';
import ShareCampaignDialog from "../../dialogs/shareCampaign/ShareCampaignDialog";
import styles from "../../../assets/css/assetsLayout.module.css";

const AssetsLayout = (props) => {
    const {
        campaignAssets: campaignAssetsBase,
        campaignElementTypes: campaignElementTypesBase,
        campaignAssetSections: campaignAssetSectionsBase,
        dialogEditAssetSections: dialogEditAssetSectionsBase,
        openDialogUploadFile: openDialogUploadFileBase,
        openDialogEditCampaign: openDialogEditCampaignBase,
        dialogShareCampaign: dialogShareCampaignBase,
        selectedCampaign: selectedCampaignBase, 
    } = React.useContext(CampaignContext);

    const [campaignAssets, setCampaignAssets] = campaignAssetsBase;
    const [campaignElementTypes, setCampaignElementTypes] = campaignElementTypesBase;
    const [campaignAssetSections, setCampaignAssetSections] = campaignAssetSectionsBase;
    const [dialogEditAssetSections, setDialogEditAssetSections] = dialogEditAssetSectionsBase;
    const [, setOpenDialogUploadFile] = openDialogUploadFileBase;
    const [, setOpenDialogEditCampaign] = openDialogEditCampaignBase;
    const [dialogShareCampaign, setDialogShareCampaign] = dialogShareCampaignBase;
    const [selectedCampaign, setSelectedCampaign] = selectedCampaignBase;


    const [types, setTypes] = React.useState([]);
    const [sectionData, setSectionData] = React.useState([]);
    const theme = useTheme();

    const [assets, setAssets] = React.useState(new Map());

    const [banner, setBanner] = React.useState([]);

    const componentRef = React.useRef();

    React.useEffect(() => {
        const runEffect = async () => {
            if (!campaignAssets || campaignAssets.length === 0) {
                setAssets(new Map());
                setTypes([]);
                return;
            }
            const typesUniqueId = [...new Set(campaignAssets.map(x => x.elementTypeId))];
            const _types = campaignElementTypes.filter(x => typesUniqueId.includes(x.id));
            setTypes(_types);

            let elementTypeMap = new Map();

            _types.forEach(element => {
                elementTypeMap.set(element.name, campaignAssets.filter(x => x.elementTypeId === element.id))
            });
            setAssets(elementTypeMap);
            // find if there is a folder with banners
            const bannerFile = campaignAssets.find(x => x.name.split(".")[1] === "zip");
            if (bannerFile) {
                const data = await fetchBanner(bannerFile.jobNumber, bannerFile.name);
                setBanner(data);
            } else {
                setBanner([]);
            }
        };
        runEffect();
    }, [campaignAssets]);

    const handleOpenDialogEditSection = (data) => {
        setSectionData(data);
        setDialogEditAssetSections(true);
    }

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <div style={{
                    marginTop: 85,
                    color: theme.palette.text.primary
                }}>
                    <div className={styles.subMenu}>

                        <HeaderSection
                            selectedCampaign={selectedCampaign}
                        />
                        {/* This is a temp solution to handle print */}
                        {/* <ActionMenu /> */}
                        <div className={styles.butMenu}>
                            <MenuButton
                                text="Rediger"
                                func={() => setOpenDialogEditCampaign(true)}
                            />
                            <MenuButton
                                text="Del"
                                func={() => setDialogShareCampaign(true)}
                            />
                            <ReactToPrint
                                trigger={() => <span
                                    className="action-menu-button"
                                >
                                    Print
                                </span>}
                                content={() => componentRef.current}
                            />
                            <MenuButton
                                text="Tilføj element"
                                func={() => setOpenDialogUploadFile(true)}
                                isActive={true}
                            />
                        </div>
                    </div>
                    <PrintableComponent
                        setOpenDialogUploadFile={(value) => setOpenDialogUploadFile(value)}
                        setOpenDialogEditCampaign={(value) => setOpenDialogEditCampaign(isValidDateValue)}
                        types={types}
                        assets={assets}
                        campaignAssetSections={campaignAssetSections}
                        handleOpenDialogEditSection={(data) => handleOpenDialogEditSection(data)}
                        banner={banner}
                        ref={componentRef}
                    />
                </div>
            </Box>

            {!dialogEditAssetSections ? undefined :
                <DialogUploadFile
                    isEdit={true}
                    sectionData={sectionData}
                />}

            {!dialogShareCampaign ? undefined :
                <ShareCampaignDialog />} 
        </>

    )
};

export default AssetsLayout;