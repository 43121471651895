import { useTheme } from '@emotion/react';
import React from 'react';
import styles from '../../../../assets/css/tag.module.css'

export default function HeaderTag({ tag }) {
    const theme = useTheme();
    return (
        <div className={styles.headerTag}>
            {tag}
        </div>
    )
}