import React from "react";
import styles from '../../../assets/css/slider.module.css'

export default function SliderImage({ position, image }) {
    return (
        <div
        className={styles.imageSlider}
            style={{
                backgroundImage: `url(${image})`,
            }}>

        </div>
    )
}