import { configurations } from "../../constants/Configuration";
import { formatBodyParamsPOST } from "../../requests/Helpers";
import { getTokenEndpoint, headers, validateTokenEndpoint } from "../../requests/Requests";

export async function getUserToken(userName, passWord) {

    var details = {
        username: userName,
        password: passWord,
        grant_type: configurations.IDENTITY_GRANT_TYPE,
        client_id: configurations.IDENTITY_CLIENT_ID,
        client_secret: configurations.IDENTITY_CLIENT_SECRET,
        scope: configurations.IDENTITY_SCOPE
    };

    const response = await fetch(getTokenEndpoint, {
        method: "POST",
        body: formatBodyParamsPOST(details),
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
    });
    return await response.json();
}

export async function tokenValidation(data, setValidToken, userName, setInvalidTokenMsg) {
    let tokenIsValid = true;

    if (data.error) {
        tokenIsValid = false;
        setInvalidTokenMsg(data.error_description);
    } else {
        saveTokenLocalStorage(data.access_token, userName);
        setInvalidTokenMsg("");
    };
    setValidToken(tokenIsValid);
}

export function validation(userName, passWord, state, setState) {
    let valid = true;

    let newState = { ...state };

    if (userName === "") {
        valid = false;
        newState.errorUser = "påkrævet";
    } else {
        newState.errorUser = "";
    };

    if (passWord === "") {
        valid = false;
        newState.errorPassword = "påkrævet";
    } else {
        newState.errorPassword = "";
    };

    setState(newState);

    return valid;
}

export async function saveTokenLocalStorage(data, userName) {
    localStorage.setItem("access_token", data);
    localStorage.setItem("username", userName);
}

export async function removeTokenLocalStorage() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
}
