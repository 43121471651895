import { Button, Dialog, DialogContent } from "@mui/material";
import { useTheme } from '@mui/system';
import ButtonCustomStyled from "./DialogComponents/DialogActionButton";
import styles from '../../assets/css/dialogContent.module.css'
export default function ConfirmActionDialog({ bodyText, onConfirm, onClose }) {
    const theme = useTheme();

    return (
        <Dialog
            onClose={onClose}
            open={true}>
            <DialogContent
                className={styles.confirmStyle}>
                <div>
                    <div className={styles.confirmTextStyle}>
                        {bodyText}
                    </div>

                    <div className={styles.confirmTextButtons}>
                        <ButtonCustomStyled
                            text="Slet"
                            onClick={() => onConfirm()}
                            primary={true} />

                        <ButtonCustomStyled
                            text="Annuller"
                            onClick={() => onClose()} />
                    </div>

                </div>

            </DialogContent>
        </Dialog>
    );
}