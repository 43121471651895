import * as React from 'react';
import TopBar from './TopBar';
import CampaignContextProvider, { CampaignContext } from '../../context/CampaignContext';
import AssetsLayout from '../campaignAssets/assetsOverviewLayout/AssetsLayout';
import SideMenuContent from '../sideMenu/SideMenuContent';
import DialogAddNewCampaign from '../dialogs/DialogAddNewCampaign';
import DialogUploadFile from '../dialogs/DialogUploadFile';
import { Button, Drawer } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@emotion/react';
import ReactToPrint from 'react-to-print';
import styles from '../../assets/css/appLayout.module.css'

export default function AppLayout(props) {
    return (
        <CampaignContextProvider>
            <AppLayoutBase {...props} />
        </CampaignContextProvider>
    )
}

function AppLayoutBase(props) {
    const {
        selectedCampaign: selectedCampaignBase,
        openDialogAddNewCampaign: openDialogAddNewCampaignBase,
        openDialogUploadFile: openDialogUploadFileBase,
        openDialogEditCampaign: openDialogEditCampaignBase
    } = React.useContext(CampaignContext);
    const [selectedCampaign,] = selectedCampaignBase;
    const [openDialogAddNewCampaign, setOpenDialogAddNewCampaign] = openDialogAddNewCampaignBase;
    const [openDialogUploadFile, setOpenDialogUploadFile] = openDialogUploadFileBase;
    const [openDialogEditCampaign, setOpenDialogEditCampaign] = openDialogEditCampaignBase;
    const theme = useTheme();

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <TopBar
                    logUserOut={() => props.logUserOut()}
                />
                <Drawer
                    variant="permanent"
                    sx={{
                        width: 320,
                        flexShrink: 0,
                        zIndex: 1,
                        [`& .MuiDrawer-paper`]: { width: 320, boxSizing: 'border-box' },
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: theme.palette.background.primary,
                        }
                    }}

                >
                    <SideMenuContent />
                    <div
                        style={{          
                            backgroundColor: theme.palette.text.primary,
                        }}
                        className={styles.sideMenuBody}>
                        <Button
                            className={styles.buttons}
                            onClick={() => setOpenDialogAddNewCampaign(true)}>
                            Opret ny kampagne
                        </Button>
                    </div>
                </Drawer>
                {!selectedCampaign ?
                    <div>No campaign selected</div> :
                    <AssetsLayout />
                }
                {!openDialogAddNewCampaign ? undefined : <DialogAddNewCampaign />}
                {!openDialogUploadFile ? undefined : <DialogUploadFile />}
                {!openDialogEditCampaign ? undefined :
                    <DialogAddNewCampaign
                        isEdit={true}
                    />}
            </Box>

        </>
    )
}