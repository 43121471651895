import React from "react";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import styles from '../../../assets/css/slider.module.css'

export default function SliderButton({ onButtonClick, right }) {
    return (
        <div
            onClick={onButtonClick}
            className={styles.buttonStyle}
            style={{
                right: right ? 40 : undefined
            }}
        > {right ? <ArrowCircleRightIcon sx={{ fontSize: "2rem" }} /> : <ArrowCircleLeftIcon sx={{ fontSize: "2rem" }} />
            }</div >
    )
}