import React, { useState } from 'react';
import note from "../../../../assets/note/note.png";
import DialogPreviewComment from '../../../dialogs/DialogPreviewComment';
import styles from "../../../../assets/css/campaignHeader.module.css"

export default function CampaignHeader({ selectedCampaign }) {
    const [dialogShowCampaignComment, setDialogShowCampaignComment] = useState(false);

    const header = selectedCampaign ? `${selectedCampaign.jobNumber} ${selectedCampaign.jobTitle} uge ${selectedCampaign.week} ${selectedCampaign.year}` : "No campaign selected";
    return (
        <>
            <div className={styles.body}>
                <div className={styles.subBody} >
                    {header}
                </div>
                <span>
                    <img
                        src={note}
                        alt="Comment"
                        onClick={() => setDialogShowCampaignComment(true)}
                    />
                </span>
        </div>
            {dialogShowCampaignComment ?
                <DialogPreviewComment
                    bodyText={selectedCampaign.comment}
                    onClose={() => setDialogShowCampaignComment(false)}
                />
                : undefined}
        </>
    )
}