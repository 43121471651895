import { Grid } from '@mui/material';
import * as React from 'react';
import CampaignItem from '../campaignItem/CampaignItem';
import { useTheme } from '@emotion/react';
import DialogPreviewComment from '../../dialogs/DialogPreviewComment';
import note from "../../../assets/note/note.png";
import { CampaignContext } from '../../../context/CampaignContext';
import styles from "./../../../assets/css/campaignAssetsSection.module.css"

export default function CampaignAssetsSection({
    data, sectionName, sectionData, handleOpenDialogEditSection, banners }) {

    const {
        campaignAssets: campaignAssetsBase,
        selectedCampaign: selectedCampaignBase,
        campaignAssetSections: campaignAssetSectionsBase
    } = React.useContext(CampaignContext);

    const [campaignAssets, setCampaignAssets] = campaignAssetsBase;
    const [selectedCampaign,] = selectedCampaignBase;
    const [, setCampaignAssetSections] = campaignAssetSectionsBase;

    const [showComment, setShowComment] = React.useState();
    const theme = useTheme();

    return (
        <div className="page-break" id={styles.body}
            > 
            <div className={styles.subBody}>
                <div
                    id={sectionName}
                    className={styles.sectionStyle}>
                    <span>
                        {sectionName}
                    </span>
                    <span>
                        <img
                            src={note}
                            alt="Comment"
                            onClick={() => setShowComment(true)}
                        />
                    </span>
                </div>
                <div
                    onClick={() => handleOpenDialogEditSection(sectionData[0])}
                    className={styles.editButton}>
                    Rediger element
                </div>
            </div>
            <Grid container spacing={0.5}>
                {
                    data.map((x, index) =>
                        // <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                        <span
                            key={index}
                            style={{
                            marginRight: 30
                        }}>
                            <CampaignItem
                                item={x}
                                sectionName={sectionName}
                                campaignAssets={campaignAssets}
                                setCampaignAssets={setCampaignAssets}
                                selectedCampaign={selectedCampaign}
                                setCampaignAssetSections={setCampaignAssetSections}
                            />
                        </span>

                    )
                }
            </Grid>
            {showComment ?
                <DialogPreviewComment
                    bodyText={sectionData.length > 0 && sectionData[0].comment ? sectionData[0].comment : ""}
                    onClose={() => setShowComment(false)}
                /> : undefined}
        </div>

    )
}