import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import SearchWithAutoSuggestAsync from './searchCampaign/SearchWithAutoSuggestAsync';
import logo from "../../assets/logo/logo.png"
import styles from '../../assets/css/appLayout.module.css'

export default function TopBar({ logUserOut, isPublic }) {
    const theme = useTheme();

    return (
            <AppBar
            position="fixed"
                className={styles.appBar}>
            <Toolbar>
                <div style={{
                    marginRight: 30
                }}>
                    <img src={logo} alt="Republica logo" />
                </div>
                {isPublic ? undefined : <SearchWithAutoSuggestAsync />}

                    <Box sx={{ flexGrow: 1 }} />
                {isPublic ? undefined :
                    (
                        <div
                            onClick={() => logUserOut()}
                            className={styles.logout}
                        >
                            Log ud
                        </div>

                    )
                }
                </Toolbar>
        </AppBar>
    );
}