
import { useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";
import AccordionItem from "../sideMenu/AccordionItem";

export default function MenuList(props) {
    const {
        campaignsToYearMap: campaignsToYearMapBase,
    } = useContext(CampaignContext);

    const [campaignsToYearMap,] = campaignsToYearMapBase;

    return (
        <div>
            {[...campaignsToYearMap.keys()].map(year =>
                <AccordionItem
                    key={year}
                    title={year}
                />
            )}
        </div>
    )
}