
import { createTheme } from "@mui/material";


export const theme = createTheme({
    palette: {
        primary: {
            main: "#133f26",
            secondary: "#f98a6a",
        },
        text: {
            primary: "#133f26",
            primary1: "#ff8862",
            login: "ffffff"
        },
        background: {
            primary: "#dff0e8"
        }
    },
});


