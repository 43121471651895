import { useTheme } from '@emotion/react';
import React, { useContext } from 'react';
import { CampaignContext } from '../../../../context/CampaignContext';
import HeaderTag from './HeaderTag';
import styles from '../../../../assets/css/tag.module.css'

export default function CampaignTags({ selectedCampaign }) {
    const theme = useTheme();
    const tags = selectedCampaign.tags;
    return (
        <div className={styles.tagStyle}>
            {
                tags.map((x, index) =>
                    <HeaderTag
                        tag={x}
                        key={index}
                    />
                )
            }
        </div>
    )
}