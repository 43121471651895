import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { downloadZipBanner, previewBannerEndpoint } from "../requests/Requests";
import { useTheme } from '@mui/system';
import { apiGetParamsReplace, getBannerSize } from "../requests/Helpers";
import ButtonCustomStyled from "./DialogComponents/DialogActionButton";
import styles from '../../assets/css/dialogContent.module.css'

export default function DialogPreviewBanner({ jobNumber, fileName, onClose, originalFileId, sectionName }) {
    const theme = useTheme();

    const [size, setSize] = useState([]);

    useEffect(() => {
        if (!fileName) return;
        const bannerSize = getBannerSize(fileName);
        setSize(bannerSize);
    }, [fileName]);


    return (
        <Dialog
            onClose={onClose}
            open={true}
            PaperProps={{ sx: { width: size[0], height: size[1] } }}
            maxWidth="xl" 
        >
            <DialogTitle className={styles.padding20}>
                <div className={styles.borderBottom}>
                    <span className={styles.previewSection}>
                        {sectionName}
                    </span>
                    <span className={styles.previewFilename}>
                        {fileName}
                    </span>
                </div>
            </DialogTitle>
            <DialogContent className={styles.padding020}>
                <iframe
                    src={apiGetParamsReplace(previewBannerEndpoint, jobNumber, fileName)}
                    title="Banner"
                    width={size[0]}
                    height={size[1]}
                />
            </DialogContent>

            <DialogActions className={styles.padding020}>
                <ButtonCustomStyled
                    text="Download"
                    onClick={() => downloadZipBanner(originalFileId)}
                    primary={true}
                />
            </DialogActions>
        </Dialog>
    );
}