import * as React from 'react';
import { deleteCampaignElement, fetchCampaignElementSections, 
    getPreviewUrl, pdfNumberOfPages, getImageThumbnailUrl } from '../../requests/Requests';
import { isPdf } from '../../requests/Helpers';
import ReactPlayer from "react-player";
import VideoPreviewDialog from '../../dialogs/VideoPreviewDialog';
import PdfPreviewDialog from '../../dialogs/PdfPreviewDialog';
import ConfirmActionDialog from '../../dialogs/ConfirmActionDialog';
import deleteIcon from "../../../assets/delete/delete.png";
import DialogPreviewBanner from '../../dialogs/DialogPreviewBanner';
import styles from '../../../assets/css/campaignItem.module.css'
export default function CampaignItem({ item, sectionName, isPublic, campaignAssets,
    setCampaignAssets, selectedCampaign, setCampaignAssetSections
}) {
    const { name } = item;

    const [videoDialog, setVideoDialog] = React.useState(false);
    const [pdfDialog, setPdfDialog] = React.useState(false);
    const [pdfUrls, setPdfUrls] = React.useState([]);
    const [bannerDialog, setBannerDialog] = React.useState(false);

    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const diloagText = "Vil du slette elementet?";

    const handlePreviewImage = async (item) => {
        if (isPdf(item)) {
            await populatePdfPreviewUrls(item);
        } else {
            // if it is an img still reuse same dialog
            setPdfUrls([getPreviewUrl(item.id, 1)]);

            setPdfDialog(true);
        }
    }

    const populatePdfPreviewUrls = async (item) => {
        // check number of pages
        const pages = await pdfNumberOfPages(item.id);

        let _urls = [];
        for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
            let endpoint = await getPreviewUrl(item.id, pageNumber);
            _urls = [..._urls, endpoint];
        };
        setPdfUrls(_urls);
        setPdfDialog(true);
    }

    const handleCloseDialog = () => {
        setVideoDialog(false);
        setPdfDialog(false);
        setPdfUrls([]);
    }

    const handleDeleteCampaignElement = async (item) => {
        await deleteCampaignElement(item.id);
        const assetsClone = [...campaignAssets];
        setCampaignAssets(assetsClone.filter(x => x.id !== item.id));

        setShowConfirmDialog(false);
        // re-fetch sections
        const _sections = await fetchCampaignElementSections(selectedCampaign.id);
        setCampaignAssetSections(_sections);
    }

    const handleOpenPreviewOriginalDialog = () => {
        if (sectionName === "HTML banner") {
            setBannerDialog(true);
            return;
        }
        const fileExention = name.split(".")[1];

        switch(fileExention) {
            case "mp4":
                setVideoDialog(true);
              break;
            default:
                handlePreviewImage(item);
          }
    }

    const getItemName = (name) => {
        return (
            <div className={styles.itemStyle}>
                {name}
            </div>
        );
    } 

    return ( 
        <>
            <div className={styles.menuStyle}>
                        <div className={styles.itemMenuStyle}>
                            <div style={{
                                height: 246,
                                backgroundImage: `url(${getImageThumbnailUrl(item.id)}`,
                                width: 180,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center"
                            }}
                                onClick={() => handleOpenPreviewOriginalDialog()}
                            ></div>
                            {getItemName(item.name)}
                            {isPublic ? undefined : (
                                <div
                                    onClick={() => setShowConfirmDialog(true)}
                                    className={styles.showDialog}>
                                    <img src={deleteIcon} alt="Delete" />
                                </div>
                            )}
                        </div>
                {/* )
                } */}
            <div>

                </div>
        </div>
            {videoDialog ? (
                <VideoPreviewDialog
                    itemId={item.id}
                    onClose={() => setVideoDialog(false)}
                    id={item.id}
                    fileName={item.name}
                    sectionName={sectionName} />
            ) : undefined
            }
            {pdfDialog ? (
                <PdfPreviewDialog
                    onClose={() => handleCloseDialog()}
                    urls={pdfUrls}
                    id={item.id}
                    fileName={item.name}
                    sectionName={sectionName}
                />
            ) : undefined}
            {!showConfirmDialog ? undefined :
                <ConfirmActionDialog
                    bodyText={diloagText}
                    onClose={() => setShowConfirmDialog(false)}
                    onConfirm={() => handleDeleteCampaignElement(item)}
                />}
            {bannerDialog ? (
                <DialogPreviewBanner
                    jobNumber={item.jobNumber}
                    fileName={item ? item.name.split(".")[0] : ""}
                    onClose={() => setBannerDialog(false)}
                    originalFileId={item.originalBannerFileId}
                    sectionName={sectionName}
                />
            ) : undefined}
        </>

    )
}