export const campaignAssetsTypeLabels = {
    "Annonce": "Annoncer",
    "BonFlyer": "Bon flyer",
    "TVC": "TVC",
    "Banner": "HTML bannere"
}

export const campaignElementType = [
    { id: 1, name: "Annonce", displayName: "Annonce" },
    { id: 2, name: "BonFlyer", displayName: "Bon flyer" },
    { id: 3, name: "TVC", displayName: "TVC" },
    { id: 4, name: "Banner", displayName: "HTML banner" },
];

export const customersEnum = [
    { id: 24, name: "SB", displayName: "SB" },
    { id: 25, name: "KV", displayName: "KV" },
    { id: 29, name: "Irma", displayName: "Irma" },
    { id: 30, name: "DB", displayName: "DB" },
    { id: 31, name: "Coop365", displayName: "Coop365" },
    { id: 32, name: "Fakta", displayName: "Fakta" },
    { id: 33, name: "SB/KV", displayName: "SB/KV" },
    { id: 34, name: "SB/KV/DB", displayName: "SB/KV/DB" },
    { id: 35, name: "jem & fix", displayName: "jem & fix" },
    { id: 36, name: "Coop", displayName: "Coop" }
];

