import * as React from 'react';
import QuickLinks from '../quickLinks/QuickLinks';
import CampaignAssetsSection from '../campaignSection/CampaignAssetsSection';

const PrintableComponent = React.forwardRef((props, ref) => {

    const { types, assets, campaignAssetSections,
        handleOpenDialogEditSection, banner
    } = props;

    return (
        <div ref={ref}>

            <QuickLinks
                campaignElementTypes={types.map(x => x.name)}
            />
            {types.map(type =>
                assets.has(type.name) ?
                    (<CampaignAssetsSection
                        key={type.id}
                        data={assets.has(type.name) ? assets.get(type.name) : []}
                        sectionName={type.name}
                        sectionData={campaignAssetSections.filter(x => x.sectionType === type.id)}
                        handleOpenDialogEditSection={(data) => handleOpenDialogEditSection(data)}
                        banners={banner}
                    />) : undefined
            )}
        </div>
    )
});

export default PrintableComponent;