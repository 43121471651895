import styled from "@emotion/styled";
import { Button } from "@mui/material";

const DialogActionButton = styled(Button)
    `
background-color: ${props => props.primary ? '#133f26' : "none"};
color: ${props => props.primary ? "#ffffff" : "#133f26"};
width: 120px;
height: 30px;
font-family: "HillRegular";
font-size: 16px;
text-transform: none;
text-decoration: ${props => props.primary ? 'none' : "underline"};
border-radius: 0px;
    &:hover {
        background-color: ${props => props.primary ? '#133f26' : "none"};
        };
    &&& {
        &.Mui-disabled {
            color:  ${props => props.primary && '#ffffff'};
            }
    }
`;
export default function ButtonCustomStyled({ onClick, text, primary, disabled }) {
    return (
        <DialogActionButton
            onClick={() => onClick()}
        primary={primary}
        disabled={disabled}>
            {text}
        </DialogActionButton>
    )
}