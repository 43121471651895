import React, { useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { fetchSingleCampaign, searchCampaigns } from '../../requests/Requests';
import { CampaignContext } from '../../../context/CampaignContext';
import { useTheme } from '@emotion/react';
import { useNavigate } from "react-router";

export default function SearchWithAutoSuggestAsync() {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    // const loading = open && options.length === 0;
    const [loading,] = React.useState(false);
    const { selectedCampaign: selectedCampaignsBase } = useContext(CampaignContext);
    const [, setSelectedCampaign] = selectedCampaignsBase;

    const theme = useTheme();
    let navigate = useNavigate();

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleSearchCampaign = async (searchText) => { 
        if (searchText.length < 2) {
            return;
        }

        const searchString = searchText.replace(new RegExp(/\//gm), ""); // when searching for customer users use "/" fx sb/db/kv
        const data = await searchCampaigns(searchString);
        setOptions(data);
        setOpen(true);
    };

    const handleSelectedCampaign = async (id) => {

        if (!id) {
            console.error("can not find campaign");
            return;
        };

        const campaignDto = await fetchSingleCampaign(id);
        setSelectedCampaign(campaignDto);
        handleView(id);
    }

    const handleView = (id) => {
        navigate("/campaign/" + id);
    }

    return (
        <Autocomplete
            id="asynchronous-demo"
            sx={{ width: 560 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            filterOptions={(x) => x} 
            getOptionLabel={(option) => option.title}
            options={options}
            loading={loading}
            onChange={(e, value) => handleSelectedCampaign(value.id)}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label=""
                    onChange={(e) => handleSearchCampaign(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            color: "#808080",
                            backgroundColor: "#ffffff",
                            height: 40,
                            paddingTop: 2
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )
            }
        />
    );
}