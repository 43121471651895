import React, { useState, useEffect, createContext } from "react";
import {
    fetchCampaignAssets,
    fetchCampaignElementSections, fetchSingleCampaign, fetchYearsCampaignsCreated,
    getCampaignElementTypes
} from "../components/requests/Requests";
import { useParams } from "react-router";

export const CampaignContext = createContext({
    campaigns: [],
    selectedCampaign: undefined,
    openDialogAddNewCampaign: false,
    openDialogEditCampaign: false,
    campaignAssets: [],
    loading: false,
    openDialogUploadFile: false,
    campaignsYears: [],
    campaignsToYearMap: new Map(),
    campaignElementTypes: [],
    campaignAssetSections: [],
    dialogEditAssetSections: false,
    dialogShareCampaign: false
});

const Index = ({ children }) => {
    // used for search field
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(undefined);

    const [openDialogAddNewCampaign, setOpenDialogAddNewCampaign] = useState(false);
    const [campaignAssets, setCampaignAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDialogUploadFile, setOpenDialogUploadFile] = useState(false);
    // used for side menu 
    const [campaignsYears, setCampaignsYears] = useState([]);
    const [campaignsToYearMap, setCampaignsToYearMap] = useState(new Map());

    const [campaignElementTypes, setCampaignElementTypes] = useState([]); // db enum 

    const [openDialogEditCampaign, setOpenDialogEditCampaign] = useState(false);

    const [campaignAssetSections, setCampaignAssetSections] = useState([]);
    const [dialogEditAssetSections, setDialogEditAssetSections] = useState(false);

    const [dialogShareCampaign, setDialogShareCampaign] = useState(false);

    const token = localStorage.getItem("access_token");

    let { campaignId } = useParams();

    // if user access page with an internal link, set selected campaign from the router params
    useEffect(() => {
        const runEffect = async () => {

            if (campaignId) {
                const _campaign = await fetchSingleCampaign(campaignId);
                setSelectedCampaign(_campaign);
            }

        };
        runEffect();
    }, []);

    useEffect(() => {
        const runEffect = async () => {
            if (!token) return;
            const _campaignElementTypes = await getCampaignElementTypes();
            setCampaignElementTypes(_campaignElementTypes);
        };
        runEffect();
    }, []);

    useEffect(() => {
        const runEffect = async () => {
            if (!token) return;
            const years = await fetchYearsCampaignsCreated();
            setCampaignsYears(years);

            let _campaignsYearMap = new Map();
            // create map 
            years.forEach(year => _campaignsYearMap.set(year, []));

            setCampaignsToYearMap(_campaignsYearMap);
        };
        runEffect();
    }, []);

    useEffect(() => {
        if (!selectedCampaign) {
            return;
        }
        setLoading(true);
        const runEffect = async () => {
            const campaignAssets = await fetchCampaignAssets(selectedCampaign.id);
            setCampaignAssets(campaignAssets);
            // get campaign sections to display section comment
            const _sections = await fetchCampaignElementSections(selectedCampaign.id);
            setCampaignAssetSections(_sections);
        };
        runEffect();
    }, [selectedCampaign]);

    return (
        <CampaignContext.Provider
            value={{
                campaigns: [campaigns, setCampaigns],
                selectedCampaign: [selectedCampaign, setSelectedCampaign],
                openDialogAddNewCampaign: [openDialogAddNewCampaign, setOpenDialogAddNewCampaign],
                campaignAssets: [campaignAssets, setCampaignAssets],
                loading: [loading, setLoading],
                openDialogUploadFile: [openDialogUploadFile, setOpenDialogUploadFile],
                campaignsYears: [campaignsYears, setCampaignsYears],
                campaignsToYearMap: [campaignsToYearMap, setCampaignsToYearMap],
                campaignElementTypes: [campaignElementTypes, setCampaignElementTypes],
                openDialogEditCampaign: [openDialogEditCampaign, setOpenDialogEditCampaign],
                campaignAssetSections: [campaignAssetSections, setCampaignAssetSections],
                dialogEditAssetSections: [dialogEditAssetSections, setDialogEditAssetSections],
                dialogShareCampaign: [dialogShareCampaign, setDialogShareCampaign]
            }}>
            {children}
        </CampaignContext.Provider>
    )
}

export default Index;