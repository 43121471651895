import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTheme } from '@mui/system';
import ButtonCustomStyled from "./DialogComponents/DialogActionButton";
import styles from '../../assets/css/dialogContent.module.css'

export default function DialogPreviewComment({ bodyText, onClose }) {
    const text = bodyText === "" ? "Ingen kommentar" : bodyText;
    const theme = useTheme();
    return (
        <Dialog
            onClose={onClose}
            open={true}>
            <DialogContent
                className={styles.previewCommentBody}>
                <div>
                    <div className={styles.previewCommentTitle}>
                        Kommentar
                    </div>
                    <div className={styles.previewCommentText}>
                        {text}
                    </div>
                    <div className={styles.previewCommentButtonBody}>
                        <div className={styles.marginTop30}>
                            <ButtonCustomStyled
                                text="Ok"
                                onClick={() => onClose()} 
                                primary={true} />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}