import * as React from 'react';
import { removeTokenLocalStorage, validateTokenFromStorage } from '../login/logic/AuthHelper';
import LogInForm from '../login/components/LogInForm';
import AppLayout from "../layout/AppLayout";

export default function StartPage(props) {
    const [validToken, setValidToken] = React.useState(false);

    React.useEffect(() => {
        const runEffect = async () => {
            let isValid = true;
            const token = localStorage.getItem("access_token");
            if (!token) {
                isValid = false; // we do not kill the token after 8 h yet
            }
            setValidToken(isValid);
        };
        runEffect();
    }, []);

    const logUserOut = () => {
        removeTokenLocalStorage();
        setValidToken(false)
    }
    return (
        <>
            {!validToken ?
                <LogInForm
                    setValidToken={(value) => setValidToken(value)} /> : (
                    <AppLayout
                        logUserOut={() => logUserOut()}
                        validToken={validToken} />
                )
            }
        </>
    );
}