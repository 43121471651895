import React, { useContext } from 'react';

import { useTheme } from '@mui/system';
import { Box } from '@mui/material';
import MenuList from '../sharedComponents/MenuList';
import styles from '../../assets/css/sideMenu.module.css'

export default function SideMenuContent(props) {
    const theme = useTheme();

    return (
        <Box sx={{ overflow: 'auto' }}
            className={styles.sideMenuBox}>
            <div className={styles.minHeightOnMenu}></div>

            <MenuList />
        </Box>

    )
}
