import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { downloadFile } from "../requests/Requests";
import SliderButton from "./DialogComponents/SliderButton";
import SliderImage from "./DialogComponents/SliderImage";
import { useTheme } from '@mui/system';
import ButtonCustomStyled from "./DialogComponents/DialogActionButton";
import styles from '../../assets/css/dialogContent.module.css'

export default function PdfPreviewDialog({ urls, onClose, id, fileName, sectionName }) {
    const [imageIndex, setImageIndex] = useState(0);
    const theme = useTheme();

    return (
        <Dialog
            onClose={onClose}
            open={true}
            maxWidth="xl"
            // PaperProps={{
            //     sx: {
            //         height: 902
            //     }
            // }}
        >
            <DialogTitle className={styles.padding20}>
                <div style={{
                    borderBottom: `1px solid #e9e9e9`
                }}>
                <span className={styles.previewSection}>
                    {sectionName}
                </span>
                <span className={styles.input}>
                    {fileName}
                </span>

                </div>
            </DialogTitle>
            <DialogContent className={styles.padding020}>
                {imageIndex === 0 ? <></> :
                    <SliderButton
                        onButtonClick={() => setImageIndex(imageIndex - 1)} />
                }
                <div>
                    <SliderImage image={urls[imageIndex]} position='center' />
                </div>
                {imageIndex === urls.length - 1 ? <></> :
                    <SliderButton right onButtonClick={() => setImageIndex(imageIndex + 1)} />
                }
            </DialogContent>
            <DialogActions className={styles.padding020}>
                <ButtonCustomStyled
                    text="Download"
                    onClick={() => downloadFile(id, fileName)}
                    primary={true}
                />
            </DialogActions>
        </Dialog>
    );
}