import React, { useState } from 'react';
import { getUserToken, tokenValidation, validation } from '../logic/AuthHelper';
import { useTheme } from '@emotion/react';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import FormHelperText from '@mui/material/FormHelperText';
import logo from "../../../assets/logo/logo.png";
import styles from '../../../assets/css/login.module.css'

const LoginButton = styled(Button)({
    backgroundColor: "#ffffff",
    color: "#133f26",
    fontFamily: "HillBold",
    fontSize: 21,
    width: 120,
    height: 30,
    ':hover': {
        color: "#133f26",
        backgroundColor: '#F0F0F0',
        right:'auto'
    },
});

export default function LogInForm({ setValidToken }) {
    const [userName, setUserName] = useState("");
    const [passWord, setPassWord] = useState("");

    const [state, setState] = useState({
        errorUser: "",
        errorPassword: ""
    });

    const [invalidTokenMsg, setInvalidTokenMsg] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const theme = useTheme();

    const userLogIn = async () => {

        if (!validation(userName, passWord, state, setState)) {
            return;
        }

        const data = await getUserToken(userName, passWord);

        await tokenValidation(data, setValidToken, userName, setInvalidTokenMsg);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div style={{
            backgroundColor: theme.palette.text.primary,
            height: "100vh"
        }}>
            <div className={styles.loginImage}>
                <img src={logo} alt="Republica logo" />
            </div>
            <div
                className={styles.loginBox}>
                <div>
                <div
                    className={styles.loginText}>
                    Login
                </div>
                <div
                    style={{
                        marginBottom: 20
                    }}>
                    <FormControl
                        variant="standard"
                        className={styles.loginForm}>
                        <InputLabel
                            htmlFor="standard-adornment-password"
                            className={styles.inputText}
                            style={{
                                color: state.errorUser ? "#ff0000" : "#ffffff",
                            }}
                        >
                            {state.errorUser ? state.errorUser : "Bruger navn"}
                        </InputLabel>
                        <Input
                            id="standard"
                            type='text'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            inputProps={{
                                style: {
                                    color: '#ffffff',
                                    borderBottom: "2px solid #ffffff",
                                    fontFamily: "HillRegular",
                                    fontSize: 21
                                }
                            }}
                        />
                    </FormControl>
                </div>

                <div
                    style={{
                        marginBottom: 20
                    }}>
                    <FormControl
                        variant="standard"
                        className={styles.loginForm}>
                        <InputLabel
                            htmlFor="standard-adornment-password"
                            className={styles.inputText}
                            style={{
                                color: state.errorPassword ? "#ff0000" : "#ffffff",
                            }}
                        >
                            {state.errorPassword ? state.errorPassword : "Password"}
                        </InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={passWord}
                            onChange={(e) => setPassWord(e.target.value)}
                            inputProps={{
                                style: {
                                    color: '#ffffff',
                                    borderBottom: "2px solid #ffffff",
                                    fontFamily: "HillRegular",
                                    fontSize: 21,
                                    
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ?
                                            <VisibilityOff style={{ color: '#ffffff' }} /> : <Visibility style={{ color: '#ffffff' }} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <LoginButton
                    onClick={() => userLogIn()}
                >
                    Login
                </LoginButton>
                <div
                    className={styles.invalid}>
                    {invalidTokenMsg}
                </div>
            </div>
        </div>
        </div>
    );
}