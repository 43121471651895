import React, { useState } from 'react';
import { style, useTheme } from '@mui/system';
import styles from '../../../assets/css/quickLinks.module.css'

export default function QuickLinks({ campaignElementTypes, isActive }) {

    const [state, setState] = useState(new Map());
    const [prevActive, setPrevActive] = useState(campaignElementTypes[0]);

    React.useEffect(() => {
        const tempState = new Map();
        campaignElementTypes.forEach(x =>
            tempState.set(x, false));
        setState(tempState);
        setActive(campaignElementTypes[0]);

    }, [campaignElementTypes]);


    const setActive = (value) => {
        if (value === prevActive) return;
        let stateClone = new Map(state);
        stateClone.set(value, true);
        stateClone.set(prevActive, false)
        setState(stateClone);
        setTimeout(() => {
            setPrevActive(value);
        }, 0);
    }

    const theme = useTheme();

    return (
        <div className={styles.body}>
            {campaignElementTypes.map((x, index) =>
                <div
                    key={index}
                    onClick={
                        () => { document.getElementById(x).scrollIntoView({ behavior: "smooth" }); setActive(x) }
                    }
                    className={styles.linkStyle}
                    style={{
                        fontFamily: state.get(x) === true && "HillBold"
                    }}>
                    {x}
                </div>
            )}
        </div>
    )
}