import { identityService, service } from "./Endpoints";
import { apiGetParamsReplace, getToken } from "./Helpers";

// file download controller
export const previewFileEndpoint = service + '/campaign-elements-download/preview/file/{0}/{1}';
const downloadPdfEndpoint = service + "/campaign-elements-download/{0}";
export const getThumbnail = service + "/campaign-elements-download/thumbnail/{0}/{1}";

// campaign controller
export const getAllCampaigns = service + '/campaigns/get-all';
export const createNewCampaign = service + '/campaigns/create';
export const getSingleCampaign = service + '/campaigns/{0}';
export const searchCampaignEndpoint = service + '/campaigns/search/{0}';
const yearsCampaignsCreatedEndpoint = service + '/campaigns/get-campaign-years';
const getCampaignsOfTheYearEndpoint = service + '/campaigns/get-campaigns/{0}';
const getAllTagsEndpoint = service + '/campaigns/tags';
const updateCampaignEndpoint = service + '/campaigns/{0}';


// campaign element controller
export const getFileEndpoint = service + '/campaign-elements/preview';
export const getCampaignAssetsEndpoint = service + '/campaign-elements/{0}';
export const createCampaignElementEndpoint = service + '/campaign-elements';
const createMultipleElementsEndpoint = service + '/campaign-elements/multiple';
const getCampaignElementTypesEndpoint = service + '/campaign-elements/types';
const getNumberOfPagesPdfEndpoint = service + '/campaign-elements/pages/{0}';
const deleteCampaignElementEndpoint = service + '/campaign-elements/{0}';
const getCampaignSectionsEndpoint = service + '/campaign-elements/sections/{0}';
const patchAssetsSection = service + "/campaign-elements/sections/{0}";
const getBanners = service + "/campaign-elements/{0}/banners/{1}/folders";
const getSingleElementEndpoint = service + "/campaign-elements/element/{0}";
const updateSectionElements = service + "/campaign-elements";

export const getTokenEndpoint = service + '/users/connect/token';

export const previewBannerEndpoint = service + "/ShowCase/{0}/HTML banner/Unpacked/{1}/index.html";

// public 
const getCampaignInfo = service + "/campaign-elements-public/{0}";
const getCampaignElementsByType = service + "/campaign-elements-public";

// parameters url replacer
const createParameterReplacerEndpoint = service + "/public-url";
const getParametersByReplacerEndpoint = service + "/public-url/{0}";
const shareCampaignEndpoint = service + "/public-url/emails";

export const headers = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*",
    Authorization: "Bearer " + getToken()
};

export async function fetchAllCampaigns() {
    const response = await fetch(getAllCampaigns, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
};

export async function fetchYearsCampaignsCreated() {
    const response = await fetch(yearsCampaignsCreatedEndpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
};


export async function fetchSingleCampaign(campaignId) {
    const endpoint = apiGetParamsReplace(getSingleCampaign, campaignId);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
};

export async function createCampaign(campaignDto) {
    const response = await fetch(createNewCampaign, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + getToken()
        },
        body: JSON.stringify(campaignDto) // body data type must match "Content-Type" header
    });

    const createdCampaign = await response.json();
    return createdCampaign;
}

export async function searchCampaigns(searchText) {
    const endpoint = apiGetParamsReplace(searchCampaignEndpoint, searchText);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    const autosuggestData = resJson.map(x => {
        return ({
            id: x.id,
            title: `${x.jobNumber} ${x.jobTitle} uge ${x.week} ${x.year}`
        })
    }
    );
    return autosuggestData;
};

export async function fetchCampaignAssets(campaignId) {
    const endpoint = apiGetParamsReplace(getCampaignAssetsEndpoint, campaignId);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
};


export async function createCampaignElement(formBody) {
    const response = await fetch(createCampaignElementEndpoint, {
        method: 'POST',
        body: formBody, // body data type must match "Content-Type" header
        headers: headers
    });
}

export async function getCampaignsOfTheYear(year) {
    const endpoint = apiGetParamsReplace(getCampaignsOfTheYearEndpoint, year);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
};

export async function getCampaignElementTypes() {
    const response = await fetch(getCampaignElementTypesEndpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
} 

export async function pdfNumberOfPages(elementId) {
    const endpoint = apiGetParamsReplace(getNumberOfPagesPdfEndpoint, elementId);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
};

export async function pdfPagePreview(elementId, pageNumber) {
    const endpoint = apiGetParamsReplace(previewFileEndpoint, elementId, pageNumber);
    const response = await fetch(endpoint, {
        headers: headers
    });

    return response;
};

export async function fetchAllTags() {
    const response = await fetch(getAllTagsEndpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
}

export async function updateCampaign(campaignId, body) {
    const endpoint = apiGetParamsReplace(updateCampaignEndpoint, campaignId);
    const response = await fetch(endpoint, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: "Bearer " + getToken()
        },
    });
    const resJson = await response.json();
    return resJson;
} 

export async function deleteCampaignElement(elementId) {
    const endpoint = apiGetParamsReplace(deleteCampaignElementEndpoint, elementId);
    await fetch(endpoint, { method: 'DELETE', headers: headers });
}

export async function fetchCampaignElementSections(campaignId) {
    const endpoint = apiGetParamsReplace(getCampaignSectionsEndpoint, campaignId);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
}

export async function updateAssetsSection(sectionId, comment) {
    const endpoint = apiGetParamsReplace(patchAssetsSection, sectionId);
    await fetch(endpoint, {
        method: 'PATCH',
        body: JSON.stringify({ comment: comment }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + getToken()
        },
    });
} 

export async function downloadFile(id, fileName) {
    const url = apiGetParamsReplace(downloadPdfEndpoint, id);
    fetch(url, { headers: headers })
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        })
        .catch(console.error);
}

export async function fetchBanner(jobNumber, zipFileName) {
    const endpoint = apiGetParamsReplace(getBanners, jobNumber, zipFileName);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
}

export async function getSingleElement(id) {
    const endpoint = apiGetParamsReplace(getSingleElementEndpoint, id);
    const response = await fetch(endpoint, {
        headers: headers
    });
    const resJson = await response.json();
    return resJson;
}


export async function downloadZipBanner(id) {
    // get banner zip element
    const element = await getSingleElement(id);
    await downloadFile(id, element.name);

}

export function getPreviewUrl(id, pageNumber) {
    if (!pageNumber) {
        pageNumber = 1;
    }
    const endpoint = apiGetParamsReplace(previewFileEndpoint, id, pageNumber);
    return endpoint;
}

export function getImageThumbnailUrl(id, pageNumber) {
    if (!pageNumber) {
        pageNumber = 1;
    }
    const endpoint = apiGetParamsReplace(getThumbnail, id, pageNumber);
    return endpoint;
}

export async function getCampaignWithoutToken(campaignId) {
    const endpoint = apiGetParamsReplace(getCampaignInfo, campaignId);
    const response = await fetch(endpoint);
    const resJson = await response.json();
    return resJson;
}

export async function getCampaignElementsByElementType(formBody) {
    const response = await fetch(getCampaignElementsByType, {
        method: 'POST',
        body: formBody, // body data type must match "Content-Type" header
    });

    const resJson = await response.json();
    return resJson;
}

export async function shareCampaignLinkViaEmail(body) {
    const response = await fetch(shareCampaignEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + getToken()
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    });
}

export async function createParameterReplacer(body) {
    const response = await fetch(createParameterReplacerEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + getToken()
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    });

    const record = await response.json();
    return record;
}

export async function getParametersByReplacer(replacerString) {
    const endpoint = apiGetParamsReplace(getParametersByReplacerEndpoint, replacerString);
    const response = await fetch(endpoint);
    const resJson = await response.json();
    return resJson;
}

export async function createMultipleCampaignElements(dto) {
    await fetch(createMultipleElementsEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + getToken()
        },
        body: JSON.stringify(dto) // body data type must match "Content-Type" header
    });
}

export async function updateElementSection(formBody) {
    await fetch(updateSectionElements, {
        method: 'PATCH',
        body: formBody, // body data type must match "Content-Type" header
        headers: headers
    });
}




