import React, { useContext, useEffect, useState } from 'react';
import { CampaignContext } from '../../context/CampaignContext';
import { useTheme } from '@mui/system';
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle, FormControl,
    InputLabel, MenuItem, OutlinedInput, Select
} from '@mui/material';
import { addNewCampaign } from '../sideMenu/BusinessLogic';
import AutoSuggestChips from './AutoSuggestChips';
import { fetchSingleCampaign, updateCampaign } from '../requests/Requests';
import { customersEnum } from '../constants/Enums';
import { validateNewCampaignDto } from './validation/CreateNewCampaignValidation';
import { fetchAllTags } from '../requests/Requests';
import StyledTextFiled from './DialogComponents/StyledTextFiled';
import ButtonCustomStyled from './DialogComponents/DialogActionButton';
import styles from '../../assets/css/dialogContent.module.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function DialogAddNewCampaign(props) {
    const {
        openDialogAddNewCampaign: openDialogAddNewCampaignBase,
        selectedCampaign: selectedCampaignBase,
        openDialogEditCampaign: openDialogEditCampaignBase,
        campaignsToYearMap: campaignsToYearMapBase,
    } = useContext(CampaignContext);
    const [, setOpenDialogAddNewCampaign] = openDialogAddNewCampaignBase;
    const [selectedCampaign, setSelectedCampaign] = selectedCampaignBase;
    const [, setOpenDialogEditCampaign] = openDialogEditCampaignBase;
    const [campaignsToYearMap, setCampaignsToYearMap] = campaignsToYearMapBase;

    const { isEdit } = props;
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [tagsAutosuggest, setTagsAutosuggest] = useState([]);
    const [state, setState] = useState({
        jobNumber: "",
        jobName: "",
        comment: "",
        customer: [],
        week: undefined,
        year: "",
        tags: []
    });

    const [validation, setValidation] = useState({
        jobNumberError: "",
        jobNameError: "",
        customerError: "",
        weekError: "",
        yearError: ""
    });

    React.useEffect(() => {
        const runEffect = async () => {
            if (isEdit) {
                if (!selectedCampaign) {
                    console.error("No campaign selected");
                    return;
                }

                const campaignEdit = { ...selectedCampaign };
                let newState = {
                    jobNumber: campaignEdit.jobNumber,
                    jobName: campaignEdit.jobTitle,
                    comment: campaignEdit.comment,
                    customer: campaignEdit.customerIds,
                    week: campaignEdit.week,
                    year: campaignEdit.year,
                    tags: campaignEdit.tags
                };
                setState(newState);
            }

            const tagsAutoSuggest = await fetchAllTags();
            setTagsAutosuggest(tagsAutoSuggest);
            document.getElementById("jobNumber") && document.getElementById("jobNumber").focus();
        };
        runEffect();
    }, []);


    const handleCreateNewCampaign = async () => {
        if (!validateNewCampaignDto(state, validation, setValidation)) {
            return;
        }
        setLoading(true);
        const newCampaign = await addNewCampaign(state);
        // set new campaign as selected
        const data = await fetchSingleCampaign(newCampaign.id);

        await handleViewWithNewCampaign(data);
        setSelectedCampaign(data);
        setOpenDialogAddNewCampaign(false);
        setLoading(false);
    };

    const handleEditCampaign = async () => {
        let campaignToEdit = { ...selectedCampaign };
        campaignToEdit.jobTitle = state.jobName;
        campaignToEdit.comment = state.comment;
        campaignToEdit.customerIds = state.customer;
        campaignToEdit.week = state.week;
        campaignToEdit.year = state.year;
        campaignToEdit.tags = state.tags;

        const campaignUpdated = await updateCampaign(campaignToEdit.id, campaignToEdit);
        setSelectedCampaign(campaignUpdated);
        setOpenDialogEditCampaign(false);
    }

    const handleViewWithNewCampaign = async (newCampaign) => {
        let mapCopy = new Map(campaignsToYearMap);
        if (campaignsToYearMap.has(newCampaign.year)) {
            let value = mapCopy.get(newCampaign.year);
            value = [...value, newCampaign];
            mapCopy.set(newCampaign.year, value);
        } else {
            mapCopy.set(newCampaign.year, [newCampaign]);
        }
        setCampaignsToYearMap(mapCopy);
    }

    const handleUpdateState = (value, field) => {
        switch (field) {
            case "jobNumber":
                setState({ ...state, jobNumber: value });
                break;
            case "jobName":
                setState({ ...state, jobName: value });
                break;
            case "comment":
                setState({ ...state, comment: value });
                break;
            case "week":
                setState({ ...state, week: value });
                break;
            case "year":
                setState({ ...state, year: value });
                break;
            default:
                throw ("Unknown filed");
        }
    }

    return (
        <Dialog
            open={true}
            onClose={() => !isEdit ? setOpenDialogAddNewCampaign(false) : setOpenDialogEditCampaign(false)}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle className={styles.title}>
                {isEdit ? "Rediger kampagnen" : "Opret ny kampagne"}
            </DialogTitle>
            <DialogContent style={{ padding: "0px 40px 40px 40px" }}>
                <div className={styles.dialogContent}>
                    <div>
                        <StyledTextFiled
                            value={state.jobNumber}
                            disabled={isEdit}
                            label="Jobnummer"
                            state={state}
                            onChange={handleUpdateState}
                            validation={validation.jobNumberError}
                            text="jobNumber"
                        />
                        <div style={{
                            marginTop: 20
                        }}>
                            <FormControl fullWidth error={!!validation.customerError ? true : false}>
                                <InputLabel
                                    id="multiple-name-label"
                                    className={styles.input}>
                                    Kunde
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={state.customer}
                                    onChange={(e) => setState({ ...state, customer: e.target.value })}
                                    input={<OutlinedInput
                                        label="Name"
                                        className={styles.input}/>}
                                    MenuProps={MenuProps}
                                >
                                    {customersEnum.map((customer) => (
                                        <MenuItem
                                            key={customer.id}
                                            value={customer.id}
                                            className={styles.input}
                                        >
                                            {customer.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <StyledTextFiled
                            value={state.jobName}
                            disabled={false}
                            label="Jobnavn"
                            onChange={handleUpdateState}
                            validation={validation.jobNameError}
                            text="jobName"
                        />
                    </div>
                    <div>
                        <StyledTextFiled
                            value={state.week}
                            disabled={false}
                            label="Uge"
                            onChange={handleUpdateState}
                            validation={validation.weekError}
                            text="week"
                        />
                        <div style={{
                            marginTop: 20
                        }}>
                            <AutoSuggestChips
                                autoSuggestData={tagsAutosuggest}
                                state={state}
                                setState={setState}
                                style={{
                                    marginTop: 10
                                }}
                            />
                        </div>
                        <StyledTextFiled
                            value={state.year}
                            disabled={false}
                            label="År"
                            onChange={handleUpdateState}
                            validation={validation.yearError}
                            text="year"
                        />
                    </div>
                </div>
                <StyledTextFiled
                    value={state.comment}
                    disabled={false}
                    label="Kommentar"
                    onChange={handleUpdateState}
                    text="comment"
                />
            </DialogContent>
            <DialogActions style={{
                padding: "0px 40px 40px 40px"
            }}>
                <ButtonCustomStyled
                    text="Annuller"
                    onClick={() => !isEdit ? setOpenDialogAddNewCampaign(false) : setOpenDialogEditCampaign(false)}
                />
                <ButtonCustomStyled
                    text="Gem kampagne"
                    onClick={() => isEdit ? handleEditCampaign() : handleCreateNewCampaign()}
                    primary={true} />
            </DialogActions>
        </Dialog>
    )
}
