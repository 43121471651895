import { stringIsEmptyOrWhiteSpace } from "../../requests/Helpers";

export function validateNewCampaignDto(state, validation, setValidation) {
    let isValid = true;
    let validationErrors = { ...validation };
    const errorMessageStandart = "Skal være udfyldt.";

    if (!state.jobNumber || stringIsEmptyOrWhiteSpace(state.jobNumber)) {
        validationErrors.jobNumberError = errorMessageStandart;
        isValid = false;
    } else {
        validationErrors.jobNumberError = "";
    };

    if (!state.jobName || stringIsEmptyOrWhiteSpace(state.jobName)) {
        validationErrors.jobNameError = errorMessageStandart;
        isValid = false;
    } else {
        validationErrors.jobNameError = "";
    };

    if (!state.customer || state.customer.length === 0) {
        validationErrors.customerError = errorMessageStandart;
        isValid = false;
    } else {
        validationErrors.customerError = "";
    };

    if (!state.week || stringIsEmptyOrWhiteSpace(state.week)) {
        validationErrors.weekError = errorMessageStandart;
        isValid = false;
    } else {
        validationErrors.weekError = "";
    };

    if (!state.year || stringIsEmptyOrWhiteSpace(state.year)) {
        validationErrors.yearError = errorMessageStandart;
        isValid = false;
    } else {
        validationErrors.yearError = "";
    };

    setValidation(validationErrors);
    return isValid;

} 