import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ReactPlayer from "react-player";
import { downloadFile, getPreviewUrl } from "../requests/Requests";
import ButtonCustomStyled from "./DialogComponents/DialogActionButton";
import styles from '../../assets/css/dialogContent.module.css'

export default function VideoPreviewDialog({ itemId, onClose, id, fileName, sectionName }) {
    const theme = useTheme();
    return (
        <Dialog
            onClose={onClose}
            open={true}
            maxWidth="xl"
        >
            <DialogTitle className={styles.padding20}>
                <div style={{
                    borderBottom: `1px solid #e9e9e9`
                }}>
                    <span className={styles.previewSection}>
                        {sectionName}
                    </span>
                    <span className={styles.previewFilename}>
                        {fileName}
                    </span>
                </div>
            </DialogTitle>
            <DialogContent className={styles.padding020}>
                <ReactPlayer
                    url={getPreviewUrl(itemId)}
                    width="100%"
                    height="100%"
                    controls />
            </DialogContent>
            <DialogActions className={styles.padding020}>
                <ButtonCustomStyled
                    text="Download"
                    onClick={() => downloadFile(id, fileName)}
                    primary={true}
                />
            </DialogActions>
        </Dialog>
    );
}