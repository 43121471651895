import React, { useState } from "react";
import { useParams } from "react-router";
import HeaderSection from "../../campaignAssets/header/HeaderSection";
import QuickLinks from "../../campaignAssets/quickLinks/QuickLinks";
import { fetchBanner, getCampaignElementsByElementType, getCampaignElementTypes, getCampaignWithoutToken, getParametersByReplacer } from "../../requests/Requests";
import CampaignSectionPublic from "../publicLayout/CampaignSectionPublic";
import styles from '../../../assets/css/assetsLayout.module.css'

export default function AssetsLayoutPublic() {
    let { param } = useParams();

    const [campaign, setCampaign] = useState(undefined);
    const [campaignElements, setCampaignElements] = useState([]); // from db
    const [campaignElementTypes, setCampaignElementTypes] = useState([]); // db enum
    const [types, setTypes] = React.useState([]);
    const [assets, setAssets] = useState(new Map());
    const [banner, setBanner] = useState([]);

    React.useEffect(() => {
        const runEffect = async () => {
            // get parameters from guid replacer 
            const dbRecord = await getParametersByReplacer(param);

            const getCampaign = getCampaignWithoutToken(dbRecord.campaignId);
            const getAssets = getElementByType(dbRecord.campaignId, dbRecord.sectionGrouping);
            const getElementTypes = getCampaignElementTypes();

            const [_campaign, _assets, _campaignElementTypes] = await Promise.all([getCampaign, getAssets, getElementTypes]);
            setCampaign(_campaign);
            setCampaignElements(_assets);
            setCampaignElementTypes(_campaignElementTypes);

        };
        runEffect();
    }, []);

    const getElementByType = async (id, param) => {
        const formData = new FormData();

        formData.append("campaignId", id);
        param.split("&").forEach((x, index) => formData.append(`ElementTypeIds[${index}]`, parseInt(x)));

        const _assets = await getCampaignElementsByElementType(formData);
        return _assets;
    }

    React.useEffect(() => {
        const runEffect = async () => {
            const typesUniqueId = [...new Set(campaignElements.map(x => x.elementTypeId))];
            const _types = campaignElementTypes.filter(x => typesUniqueId.includes(x.id));
            setTypes(_types);
            let elementTypeMap = new Map();

            _types.forEach(element => {
                elementTypeMap.set(element.name, campaignElements.filter(x => x.elementTypeId === element.id))
            });
            setAssets(elementTypeMap);
            // find if there is a folder with banners
            const bannerFile = campaignElements.find(x => x.name.split(".")[1] === "zip");
            if (bannerFile) {
                const data = await fetchBanner(bannerFile.jobNumber, bannerFile.name);
                setBanner(data);
            } else {
                setBanner([]);
            }
        };
        runEffect();
    }, [campaignElements, campaignElementTypes]);

    return (
        <div className={styles.padding20}>
            {campaign ?
                (
                    <div className={styles.headerSection}>
                        <HeaderSection
                            selectedCampaign={campaign}
                        />
                    </div>
                ) : undefined
            }
            <QuickLinks
                campaignElementTypes={types.map(x => x.name)}
            />
            {types.map(type => assets.has(type.name) ?
                <CampaignSectionPublic
                    key={type.id}
                    data={assets.has(type.name) ? assets.get(type.name) : []}
                    sectionName={type.name}
                    banner={banner}
                    types={types}
                />
                : undefined
            )}
        </div>
    );
}